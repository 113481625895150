.home, .login, .register  {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.login-container {
    display: flex;
    justify-content: center; 
    align-items: center;
}

.register-container {
    display: flex;
    justify-content: center; 
    align-items: center;
}

